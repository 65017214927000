import React, {useEffect, useState} from "react";
import DateTimePicker from 'react-datetime-picker';
import "./App.css";
import Countdown from "./components/Countdown";

function App() {
  const [endTime, setEndTime] = useState(new Date()); 
  
  return (
    <div className="App">
      <h1>Wedding Countdown</h1>
      <Countdown
        endTime={endTime.setDate(13)}
      /> 
      {/*<DateTimePicker
        onChange={setEndTime}
        value={endTime}
      />*/}
    </div>
  );
}

export default App;
