import React, {useState, useEffect} from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./styles.css";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

export default function CountdownTimer(props) {
  /*const [startTime, setStartTime] = useState(Date.now() / 1000); // use UNIX timestamp in seconds
  const [endTime, setEndTime] = useState(props.endTime ? props.endTime.getTimeSeconds : startTime + 243248);//startTime + 243248; // use UNIX timestamp in seconds
  const [remainingTime, setRemainingTime] = useState(endTime - startTime);
  const [days, setDays] = useState(Math.ceil(remainingTime / daySeconds));
  const [daysDuration, setDaysDuration] = useState(days * daySeconds);*/

  const startTime = Date.now() / 1000;
  const endTime = new Date(2021, 10, 13) / 1000;//props.endTime.getTimeSeconds;
  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  /*useEffect(() => {
    setStartTime(Date.now() / 1000);
    console.log(props.endTime)
    setEndTime(props.endTime ? props.endTime.getTimeSeconds : startTime);
    setRemainingTime(endTime - startTime);
    setDays(Math.ceil(remainingTime / daySeconds));
    setDaysDuration(days * daySeconds);
  }, [props])*/
 

  return (
    <div className="App">
      <div className="timers">
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#7E2E84"]]}
          duration={daysDuration}
          initialRemainingTime={remainingTime}
        >
          {({ elapsedTime }) =>
            renderTime("days", getTimeDays(daysDuration - elapsedTime))
          }
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#D14081"]]}
          duration={daySeconds}
          initialRemainingTime={remainingTime % daySeconds}
          onComplete={(totalElapsedTime) => [
            remainingTime - totalElapsedTime > hourSeconds,
          ]}
        >
          {({ elapsedTime }) =>
            renderTime("hours", getTimeHours(daySeconds - elapsedTime))
          }
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#EF798A"]]}
          duration={hourSeconds}
          initialRemainingTime={remainingTime % hourSeconds}
          onComplete={(totalElapsedTime) => [
            remainingTime - totalElapsedTime > minuteSeconds,
          ]}
        >
          {({ elapsedTime }) =>
            renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))
          }
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#218380"]]}
          duration={minuteSeconds}
          initialRemainingTime={remainingTime % minuteSeconds}
          onComplete={(totalElapsedTime) => [
            remainingTime - totalElapsedTime > 0,
          ]}
        >
          {({ elapsedTime }) =>
            renderTime("seconds", getTimeSeconds(elapsedTime))
          }
        </CountdownCircleTimer>
      </div>
    </div>
  );
}
